<template>
  <!-- 出稿链接 -->
  <el-dialog :title="link_title" :append-to-body="true" :visible.sync="link" :show-close="false" :lock-scroll="true">
    <p class="link_poup_title" style="width: 100%;">稿件标题：{{ outItem.title }}</p>
    <p class="link_poup_title" style="width: 100%">媒介平台：{{ outItem.medium_title }}</p>
    <div class="flex">
      <p class="link_title">发布链接：</p>
      <!-- @keyup.native.enter="get_link" -->
      <div>
        <div v-for="(item, index) in link_arr" :key="index" style="position: relative;">
          <el-input type="text" class="link_textarea" v-model="item.value" placeholder="请填写链接"></el-input>
          <div class="add_delete_icon_box">
            <i class="add" @click="add" v-show="index == 0"></i>
            <i class="delete" v-show="index > 0" @click="de_lete(index)"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="flex">
      <p class="link_poup_title">图片截图：</p>
      <el-upload action="/api/common/upload" list-type="picture-card" :limit="1" :headers="headers"
                 accept=".jpg,.png" :on-success="handleAvatarSuccess" :on-preview="handlePictureCardPreview"
                 :on-remove="handleRemove" :on-exceed="over_flow" :before-upload="beforeAvatarUpload">
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="order_url_image" alt="">
      </el-dialog>
    </div>
    <div class="flex" style="justify-content: flex-end;">
      <button @click="outWord" class="outWord" v-if="outItem.but_type == 'create'">确认出稿</button>
      <button v-else @click="outEdit" class="outWord">确认编辑并出稿</button>
    </div>

  </el-dialog>
</template>

<script>
export default {
  props: {
    outItem: Object,
    link_title: String,
  },
  watch: {
    outItem(newVal, oldVal) {
      if (newVal) {
        newVal.order_urls.forEach((item, index) => {
          this.link_arr[index].value = item
        })
      }
    }
  },
  data() {
    return {
      link: false,
      link_arr: [
        {
          value: '',
        },
        {
          value: '',
        },
      ],
      headers: {
        token: this.$user_info.token,
      },
      order_url_image: '',
      dialogVisible: false
    }
  },
  methods: {
    linkShow() {
      // console.log('接收参数', this.outItem)
      this.link = !this.link
      this.order_url_image = ''
    },
    // 出稿
    outWord() {
      if (this.linkp != '') {
        this.$confirm('确认出稿?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          let data = {}
          data.id = this.outItem.id
          data.status = 2
          data.url = ''
          data.order_url_image = this.order_url_image
          this.link_arr.forEach(item => {
            data.url += `${item.value}\n`
          })
          this.curlPost('/api/users/order/edit', data).then(res => {
            console.log(res);
            if (res.data.code === 1) {
              this.$message({
                type: 'success',
                message: '出稿成功'
              });
              this.status = {
                id: 2,
                title: '已出稿'
              }
              this.linkShow()
              this.$store.commit('MEDIAORDER_TAB', 2)
              this.$emit('get_list')
              this.link_arr = [
                {
                  value: '',
                },
                {
                  value: '',
                },
              ]
            } else {
              this.$message({
                message: res.data.msg
              });
            }
          })


        })
      } else {
        this.$message({
          type: 'warning',
          message: '请输入出稿链接'
        });
      }
    },
    outEdit() {
      console.log(this.outItem);
      this.$confirm('确认编辑链接?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {

        let data = {}
        data.id = this.outItem.id
        data.status = 2
        data.url = ''
        data.order_url_image = this.order_url_image || ''
        this.link_arr.forEach(item => {
          data.url += `${item.value}\n`
        })
        console.log(data.url);
        this.curlPost('/api/users/order/edit', data).then(res => {
          console.log(res);
          if (res.data.code === 1) {

            this.$message({
              type: 'success',
              message: '编辑成功'
            });
            this.status = {
              id: 2,
              title: '已出稿'
            }
            this.linkShow()
            this.$emit('get_list')
          } else {
            this.$message({
              message: res.data.msg
            });
          }
        })
      })
    },
    add() {
      this.link_arr.push({
        value: ''
      })
    },
    de_lete(index) {
      this.link_arr.splice(index, 1)
    },
    handleAvatarSuccess(res, file) {
      console.log(res);
      this.order_url_image = res.data.url
    },
    beforeAvatarUpload(file) {
      let testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)

      const extension =
          testmsg === 'jpg' || testmsg === 'png'
      const isLt2M = file.size / 1024 / 1024 < 5

      if (!extension) {
        this.$message.error('上传图片类型只能是 JPG,PNG格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!')
      }
      return extension && isLt2M
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {

      console.log(this.order_url_image);
    },
    over_flow(files, fileList) {
      console.log(files, fileList);
      this.$message.error('上传文件数量不能超过1个')
    }
  },
}
</script>

<style lang=scss scoped>
.link_textarea {
  display: block;
  width: 200px !important;
  margin-bottom: 10px;
}

.add_delete_icon_box {
  width: 20px;
  height: 20px;
  position: absolute;
  right: -30px;
  top: 12px;

  i {
    display: block;

    cursor: pointer;
  }

  .add {
    width: 20px;
    height: 20px;
    background: url('~@/assets/wordOp/add.png') no-repeat;
    background-size: 100%;
    position: absolute;
    top: 0;
  }

  .delete {
    width: 20px;
    height: 20px;
    background: url('~@/assets/wordOp/delete.png') no-repeat;
    background-size: 100%;
    position: absolute;
    top: 7px;

  }
}

::v-deep .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}


::v-deep .el-upload-list--picture-card .el-upload-list__item-status-label i {
  display: none;
}

::v-deep .el-upload-list__item-status-label {
  background: rgba(16, 140, 221, 1) !important;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item-actions span + span {
  margin: 0;
}

::v-deep .el-upload-list__item-preview i {
  display: none !important;
}
</style>